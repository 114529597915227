import React, { useEffect, useMemo, useRef, useState } from 'react';
import  { Box, Container, Grid, Link} from '@mui/material';
import DeskVid from "../../../../assets/Images/newDesign/DeskVideo.mp4";
import styled from '@emotion/styled';
import Paragraph from '../../Paragraph';
import {motion} from 'framer-motion';
import UperBar from '../../UperBar';
import axios from 'axios'; 
import ubantu from  '../../../../assets/Images/newDesign/U.png'  
import andoid from  '../../../../assets/Images/newDesign/A.png'  
import ios from  '../../../../assets/Images/newDesign/I.png'   
import macos from  '../../../../assets/Images/newDesign/M.png'   
import window from  '../../../../assets/Images/newDesign/W.png' 
import MenuButtons from './Components/Menu'; 
import {API_URL} from "../../../Constant/index"
const MotionGrid = motion(Grid);


const HeaderDesign = styled.div`
  min-height: 100dvh; 
  position: relative;
  z-index: 2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  @media(max-width: 900px){
    min-height: 100vh;
  }
  
`
const BgVideo = styled.video`
  width:100%;
  height:calc(100vh - 0px);
  position:absolute; 
  top:0%;
  left:0;
  object-fit:cover;
  display :block ;
  @media(max-width:499px){  
    /* display: none; */ 
    top:0;
  }
` 
const BgVideo2 = styled.video`
  width:100%;
  height:calc(100vh - 100px);
  position:absolute; 
  /* z-index: 999; */
  top:0%;
  left:50%;
  transform: translate(-50%,0%);
  display: none;
  object-fit: cover;
  @media(max-width:499px){  
    display: block !important; 
  } 
`
const Spantype = styled.span`
  font-size: 58px; 
  @media(max-width:1080px){  
    font-size: 51px !important;  
    font-weight: 0px !important;
  }  
   @media(max-width:599px){  
    font-size: 27px !important;  
    font-weight: 0px !important;
  }
`

const Home = () => { 
  const [pagestate , setPagestate] =  useState(false);
  const [location, setLocation] = useState(null);  
  const [o_sys, setO_sys] =  useState(null); 
  const [filterdButton,setFilterdButton] = useState([]);
  const [Ips , setIps] = useState([]);
  const [status , setStatus] = useState("loading");
  const main_array =  [ 
    //  {  
    //   name : "Linux", 
    //   w : "70%",  
    //   small_w : "62%",
    //   src  : linux,
    //   arr : [ 
    //     {
    //       title: "Linux (.AppImage)",
    //       link: 'https://shadownode.org/vpnexe/linux-2.0.0.AppImage',
    //       target:"blank"
    //     },
    //   ]
    //   }, 
      {  
        name : "Ubuntu", 
        w : "62px",  
        small_w : "39px",
        src  : ubantu,
        arr : [ 
          {
            title: "Ubuntu (.AppImage)",
            link: 'https://shadownode.org/vpnexe/ubuntu-2.0.0.AppImage',
            target:"blank"
          },
  
        ]
        }, 
        {  
          name : "Android", 
          w : "62px",  
          small_w : "39px",  
          src  : andoid,
          arr : [ 
            {
              title: 'Android TV',
              link: './shadow_node_android_tv.apk',
              target:"blank"
            },
            {
              title: 'Android (Premium)',
              link:'https://play.google.com/store/apps/details?id=com.shadownode.svpn',
              target:"blank"
            }, 
            {
              title: 'Android (Free)',
              link: 'https://play.google.com/store/apps/details?id=com.svpn.freeshadownode',
              target:"blank"
            },
          ]
          },
          {  
            name : "IOS", 
            w : "62px",  
            small_w : "39px",
            src  : ios,
            arr : [ 
              {
                title: 'IOS (Premium)',
                link:'https://testflight.apple.com/join/v6hQPX5p',
                target:"blank"
              },
              {
                title: 'IOS (Free)',
                link:'https://testflight.apple.com/join/lOfDBfgw',
                target:"blank"
              }, 
            ]
            }, 
            {  
              name : "Windows", 
              w : "62px",  
              small_w : "39px",
              src  : window,
              arr : [ 
                {
                  title:'Windows (64 bit)',
                  link: 'https://shadownode.org/vpnexe/x-64.exe',
                  target:"blank"
                },
                {
                  title: 'Windows (32 bit)',
                  link:'https://shadownode.org/vpnexe/x-32.exe',
                  target:"blank"
                }
              ]
              }, 
              {  
                name : "macOS", 
                w : "62px", 
                small_w : "39px", 
                src  : macos,
                arr : [ 
                  {
                    title: 'macOS(M1)',
                    link:'https://testflight.apple.com/join/v6hQPX5p',
                    target:"blank"
                  },
                  {
                    title: 'macOS(M2)',
                    link: 'https://testflight.apple.com/join/v6hQPX5p',
                    target:"blank"
                  }
                ]
                },  
   ]   

   const detectOS = () => { 

    const dataOS = [
      {
        string: navigator.platform,
        subString: 'Win',
        identity: 'Windows',
      },
      {
        string: navigator.platform,
        subString: 'Mac',
        identity: 'macOS',
      },
      {
        string: navigator.userAgent,
        subString: 'iPhone',
        identity: 'IOS',
      },
      {
        string: navigator.userAgent,
        subString: 'iPad',
        identity: 'IOS',
      },
      {
        string: navigator.userAgent,
        subString: 'iPod',
        identity: 'IOS',
      },
      {
        string: navigator.userAgent,
        subString: 'Android',
        identity: 'Android',
      },
      {
        string: navigator.platform,
        subString: 'Linux',
        identity: 'Linux',
      },
    ];
  
    for (let i = 0; i < dataOS.length; i++) {
      const dataString = dataOS[i].string;
      if (dataString.indexOf(dataOS[i].subString) !== -1) {
        return dataOS[i].identity;
      }
    }
    return 'Unknown OS';
  }; 


  useEffect(() => {
    const os = detectOS(); 
    // setO_sys(os);
    console.log("Operating System:", os); 
    const filterarray = main_array.filter((items) => items.name === os); 
    console.log("filterarray",filterarray);
    setFilterdButton(filterarray); 
    console.log("filterdButton",filterdButton);
  }, []);


  useEffect(() => { 
   const timer =  setTimeout( async() => {
        try {
          const ipResponse = await axios.get('https://api.ipify.org?format=json') 
          // console.log(ipResponse.data.ip)
          const response = await axios.get(`https://ipapi.co/${ipResponse?.data.ip}/json/`)
          .then(function (response) {
              console.log("response",response);
              const data = response.data;  
                // console.log(data)
                setLocation({
                    ip: data?.ip, 
                    city: data?.city,
                    region: data?.region,
                    country: data?.country_name,
                    asn: data?.asn,
            isp: data?.org
          });
          })
          .catch(function (error) {
              console.error(error);
          });
          
        } catch (error) {
          console.error('Error fetching location:', error);
        }
    }, 0); // 3 seconds 
    return () => clearTimeout(timer);
  },[]); 
   
  const memoizedData = useMemo(() => {
    if (location) {
      // Perform expensive calculations or transformations here
      return location;
    }
    return [];
  }, [location]);
    

  useEffect(() => { 
    const timer =  setTimeout(async () => {
      // console.log(requestData)
      const endPoint = "getServerData";
      const endPoint2 = "getFreeServerData";
      const method = "GET";
      try {
        const response = await fetch(`${API_URL}${endPoint}`, {
          method,
          headers: {
            "content-type": "application/json",
          },
        });
        const responseFreeServer = await fetch(`${API_URL}${endPoint2}`, {
          method,
          headers: {
            "content-type": "application/json",
          },
        });
        const result = await response.json();
        const resultFreeServer = await responseFreeServer.json();
        // console.log("response", result);
        if (result?.message === "succes" && resultFreeServer?.message === "succes") {
          const ips = result?.data.map((server)=> server?.ip )
          const ipsFreeServer = resultFreeServer?.data.map((server)=> server?.ip )
          const newIpsList = [...ips,...ipsFreeServer]
          setIps(newIpsList)
        }
      } catch (err) {
        // setServerList([{}])
        console.error(err);
      } 
    },0)  
    return () => clearTimeout(timer);
  },[])
  useEffect(()=>{
    console.log("location?.ip",location?.ip); 
    console.log("ips",Ips.includes(location?.ip)); 
    if(Ips.length >0 && location){

      if(Ips?.includes(location?.ip)){  
        setStatus("Protected");    
        console.log("Status:", status)   
      }else{   
        setStatus("UnProtected");    
        console.log("status: Unrprotected")
        return;
      }
    } 
  },[Ips,location])


  const videoRef = useRef(null);
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.muted = true; // Ensure the video is muted
    video.playsInline = true; // Ensure the video plays inline
    video.play().catch(error => {
      // Autoplay failed, add a user gesture to play the video
      document.addEventListener('click', () => {
        video.play().catch(error => {
          console.error('Autoplay failed:', error);
        });
      }, { once: true }); // Ensure the event is only triggered once
    });
  }, []); 



 
  return (
    <Box position={'relative'}>
      <BgVideo muted={true} autoPlay={true} playsInLine={true} loop={true}  >
       <source  src={DeskVid} type="video/mp4"/>
      </BgVideo>  
      <UperBar IP={memoizedData?.ip ? memoizedData?.ip : "loading"} location={memoizedData?.city ? `${memoizedData?.city}  ${memoizedData?.region}` :"loading"} ISP={ memoizedData?.isp ? ` ${memoizedData?.isp}` :"loading"} protected={status} />
    <HeaderDesign id="home"> 
    <div id="stars"></div>
    <div id="stars2"></div>  
     <div id="stars3"></div>  
      {/* <LockerMenu />  */}
      <Container maxWidth="xl"  sx={{ minHeight:{xs:"calc(100vh - 150px)",lg:"calc(100vh - 120px)"} ,display:"flex", flexDirection:"column", justifyContent:{xs:"start",lg:"start"}, paddingTop:{xs:"200px",md:"160px",lg:"160px"}, paddingBottom:{xs:"15%",md:"2%"},paddingLeft:{xs:"20px"},paddingRight:{xs:"20px"}}}>
      <Grid container display="flex" justifyContent="center" alignItems="start" >
          <MotionGrid item
          xs={12} md={8} display="flex" flexDirection="column" justifyContent="start" alignItems="center" gap={{xs:"2px",lg:""}}>
          <Paragraph  fontSize={{xs:"16px",lg:"30px"}}>Shadow Node</Paragraph>
            <Paragraph fontSize={{xs:"38px",sm:"51px",md:"51px",lg:"58px"}}  fontWeight={{xs:"600",lg:"600"}} lineHeight={{xs:"45px",sm:"70px",md:"72px",lg:"80px"}}><Spantype style={{fontWeight:{xs:"100",lg:"500"}}}>Your Privacy, Your Control </Spantype><br />
              Decentralized VPN</Paragraph> 
              <Paragraph  fontSize={{xs:"13px",lg:"18px"}} textAlign="center" maxWidth={{xs:"360px",lg:"620px"}} color="white" sx={{marginBottom:"5px"}} lineHeight={{xs:"18px",lg:"26px"}}>Shadow Node redefines online privacy through a decentralized VPN for secure, private, and unrestricted internet access.</Paragraph>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexWrap="wrap" gap="5px" >
              {filterdButton.map((item) => ( 
                  <MenuButtons key={item.title} src={item.src} name={item.name} w={item.w} small_w={item.small_w} array={item.arr} /> 
               ))} 
               <Paragraph  fontSize={{xs:"14px",lg:"16px"}} textAlign="center"  color="white" sx={{marginTop:{xs:"5px",lg:"5px"}}}  lineHeight={{xs:"22px",lg:"25px"}}>Also available on other platforms</Paragraph> 
               <Link href="#download" fontSize={{xs:"14px",lg:"16px"}} textAlign="center"  sx={{color:"white",textDecorationColor:"white"}} >Discover more</Link>
              </Box> 
          </MotionGrid>
        </Grid>
      </Container>
    </HeaderDesign>
    </Box>
  );
}
 
export default Home;