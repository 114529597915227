import React from 'react';
import { Grid ,Container, Box} from '@mui/material';
import Paragraph from '../../Paragraph';
import PurpleButton from '../../PurpleButton'; 
import tg from '../../../../assets/Images/Send.png'
import { Imagedata } from '../../LockerMenu/styles';
const End = () => {
    return (
        <Container data-aos="fade-up"  className='colordBox' maxWidth="xl" sx={{position:"relative",zIndex:"500",marginLeft:{xs:"20px",sm:"auto"},marginRight:{xs:"20px",sm:"auto"},padding:{xs:"50px 30px",lg:"80px 30px"},width:{xs:"calc(100% - 40px)",lg:"calc(100% - 60px)"}}}>
            <Grid container display="flex" justifyContent="center" alignItems="center">
                <Grid item container xs={12} sm={8} md={6} lg={12} display="flex" flexDirection="column" justifyContent="start" alignItems="center" gap="15px">
                    {/* <Img w="100px"  src={shadowBoxLogo} alt="pic" style={{borderRadius:"12px",boxShadow:"0 0 50px #7E58FC"}} /> */} 
                    <Paragraph fontSize="clamp(1.875rem, 1.4464rem + 2.1429vw, 3.375rem)"   fontWeight="600" whiteSpace={{xs:"wrap",lg:"nowrap"}}> 
                        Start Your Own VPN Business
                    </Paragraph>
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="start" alignItems="center" gap="20px" >  
                     <Paragraph maxWidth="800px" textAlign="center" fontSize={{xs:"11px",lg:"18px"}}  >
                        Wish to become a VPN provider yourself? Use our whitelabel services to repackage
                       Shadow Node's top of the line service as your very own!
                    </Paragraph>
                    <PurpleButton  p="5px 30px" fs="16px" href="https://t.me/shadownodeVPN"   sx={{position:"relative",zIndex:"9999",display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}>  
                        <Imagedata src={tg} alt="tg" mw="25px" />
                         Get Free Consultaion
                    </PurpleButton> 
                    </Box>
                </Grid>   
            </Grid>
        </Container>
    );
}
 
export default End;