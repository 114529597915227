import React, { useEffect, useMemo, useRef, useState } from 'react';
import  { Box, Container, Grid} from '@mui/material';
import styled from '@emotion/styled';
import Paragraph from '../../Paragraph';
import {motion} from 'framer-motion';
import UperBar from '../../UperBar';
import axios from 'axios'; 
import {API_URL} from "../../../Constant/index" 
import LockerMenu from '../../LockerMenu'
import Cardimg from '../Components/B_Detail_Card';  
import post1 from '../../../../assets/Images/Post1.png' 
import post2 from '../../../../assets/Images/Post2.png' 
import post3 from '../../../../assets/Images/Post3.png' 
import { Img } from '../../../Styles/Maintext.styles';
import Mediumtxt from '../../Mediumtxt';
import CustomButton from '../../CustomBtn'; 
import {BLOG_URL} from  "../../../Constant/index"
import {Link} from 'react-router-dom';
import BlogCard from '../Components/B_Detail_Card';
 const MotionGrid = motion(Grid);

const HeaderDesign = styled.div`
  min-height: 100dvh; 
  position: relative;
  z-index: 2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  @media(max-width: 900px){
    min-height: 100vh;
  }
  
`
const BgVideo = styled.video`
  width:100%;
  height:calc(100vh - 0px);
  position:absolute; 
  top:0;
  left:0;
  object-fit:cover;
  display :block ;
  @media(max-width:499px){  
    display: none;
  }
` 
const BgVideo2 = styled.video`
  width:100%;
  height:calc(100vh - 100px);
  position:absolute; 
  /* z-index: 999; */
  top:0%;
  left:50%;
  transform: translate(-50%,0%);
  display: none;
  object-fit: cover;
  @media(max-width:499px){  
    display: block !important; 
  } 
`
const Spantype = styled.span`
  font-size: 54px; 
  @media(max-width:599px){  
    font-size: 21px !important; 
  }
`
 
const CustomGrid = styled(Grid)`  
   background-color: #707070; 
   border-radius: 10px;
   display: flex;  
   flex-direction: column; 
   justify-content: start; 
   align-items: center; 
   gap:10px;
` 

const Excerpt = styled.div` 
     display: inline-block;
     /* border-bottom: 1px dotted black;  */
     color:#fff; 
     position : relative;
    &>.tooltiptext{     
        visibility: hidden;
        width: calc(100% - 40px);
        background-color:#262033;
        color: #fff;
         text-align: left;
         border-radius: 6px;
         padding:10px 20px;
        position: absolute;  
        top: 30px;
        left:0; 
        font-family:PoppinsL;
         /* Position the tooltip */
        z-index: 510; 
        &>.text{  
            font-size: 16px; 
            text-align: left; 
            color : white;
         }
    } 
    :hover .tooltiptext {
    visibility: visible;
}
`
const PagePost = () => { 
  // const [pagestate , setPagestate] =  useState(false);
  const [pagestate , setPagestate] =  useState(false);
  const [location, setLocation] = useState(null);  
  const [o_sys, setO_sys] =  useState(null); 
  const [filterdButton,setFilterdButton] = useState([]);
  const [Ips , setIps] = useState([]);
  const [status , setStatus] = useState("loading");
  

  useEffect(() => { 
    const timer =  setTimeout( async() => {
         try {
           const ipResponse = await axios.get('https://api.ipify.org?format=json') 
           // console.log(ipResponse.data.ip)
           const response = await axios.get(`https://ipapi.co/${ipResponse?.data.ip}/json/`)
           .then(function (response) {
               console.log("response",response);
               const data = response.data;  
                 // console.log(data)
                 setLocation({
                     ip: data?.ip, 
                     city: data?.city,
                     region: data?.region,
                     country: data?.country_name,
                     asn: data?.asn,
             isp: data?.org
           });
           })
           .catch(function (error) {
               console.error(error);
           });
           
         } catch (error) {
           console.error('Error fetching location:', error);
         }
     }, 0); // 3 seconds 
     return () => clearTimeout(timer);
   },[]); 
    
   const memoizedData = useMemo(() => {
     if (location) {
       // Perform expensive calculations or transformations here
       return location;
     }
     return [];
   }, [location]);
     
 
   useEffect(() => { 
     const timer =  setTimeout(async () => {
       // console.log(requestData)
       const endPoint = "getServerData";
       const endPoint2 = "getFreeServerData";
       const method = "GET";
       try {
         const response = await fetch(`${API_URL}${endPoint}`, {
           method,
           headers: {
             "content-type": "application/json",
           },
         });
         const responseFreeServer = await fetch(`${API_URL}${endPoint2}`, {
           method,
           headers: {
             "content-type": "application/json",
           },
         });
         const result = await response.json();
         const resultFreeServer = await responseFreeServer.json();
         // console.log("response", result);
         if (result?.message === "succes" && resultFreeServer?.message === "succes") {
           const ips = result?.data.map((server)=> server?.ip )
           const ipsFreeServer = resultFreeServer?.data.map((server)=> server?.ip )
           const newIpsList = [...ips,...ipsFreeServer]
           setIps(newIpsList)
         }
       } catch (err) {
         // setServerList([{}])
         console.error(err);
       } 
     },0)  
     return () => clearTimeout(timer);
   },[])
   useEffect(()=>{
     console.log("location?.ip",location?.ip); 
     console.log("ips",Ips.includes(location?.ip)); 
     if(Ips.length >0 && location){
 
       if(Ips?.includes(location?.ip)){  
         setStatus("Protected");    
         console.log("Status:", status)   
       }else{   
         setStatus("UnProtected");    
         console.log("status: Unrprotected")
         return;
       }
     } 
   },[Ips,location]) 
   
   
   const  [blog_data , setBlog_data] =  useState([]);
   const [featured_Data ,setFeatured_Data] = useState({});

   const apiCallA = async () => {
    try {
      const response = await fetch(`https://blog.shadownode.org/wp-json/posts/all`, {
       method: 'GET',
       // mode: 'no-cors',
       headers: {
         "content-type": "application/json",
       },
      });
    
      console.log("All response ", response); 

      const result = await response.json();
      console.log("AllPost", result); 
      setBlog_data(result);
      if (result?.message === "succes" ) { 
       
      }
    } catch (err) {
      // setServerList([{}])
      apiCallA();
      console.error(err);
    } 
   }
   const apiCallB = async () => {
    try {
      const response = await fetch(`https://blog.shadownode.org/wp-json/posts/featured`, {
       method: 'POST',
       mode: 'no-cors',
        headers: {
          "content-type": "application/json",
        },
      });
    
      const result = await response.json();
      console.log("Featured_DAta", result);  
      setFeatured_Data(result); 
     //  console.log("",)
    } catch (err) {
      // setServerList([{}])
      apiCallB();
      console.error(err);
    } 
   }
    useEffect(() => { 
      apiCallA();
      apiCallB();
   },[]) 
    
   const memofeatured = useMemo(() => {
    if (featured_Data) {
      // Perform expensive calculations or transformations here
      return featured_Data;
    }
    return [];
  }, [featured_Data]); 

  
//   const [countstring , setCountstring]  = useState(""); 
   
//  //  console.log("excerpt",excerpt); 

//  function countWordsToLimit(str, limit) { 

//      if (typeof str !== 'string' || typeof limit !== 'number' || limit < 1) {
//          throw new Error('Invalid input: Please provide a valid string and a positive number limit.');
//      }
 
//      let words = str.trim().split(/\s+/); // Split the string by whitespace and remove any extra spaces
//      let wordCount = 0;
//      let limitedWords = [];
 
//      for (let word of words) {
//          if (wordCount >= limit) {
//              break;
//          }
//          limitedWords.push(word);
//          wordCount++;
//      }
 
//      return {
//          wordCount: wordCount,
//          words: limitedWords.join(' ')
//      };
//  }
      
//  useEffect(() => {  
//   const str  = memofeatured?.excerpt 
//   // console.log("string",str);
//   const strword  = countWordsToLimit(str, 20); 
//   setCountstring(strword?.words); 
//   // console.log("Countstring",countstring)
//   }, []);  

  return (
    <Box position={'relative'}>
      {/* <BgVideo muted={true} autoPlay={true} playsInLine={true} loop={true}  >
       <source  src={DeskVid} type="video/mp4"/>
      </BgVideo>  */}
      <UperBar IP={memoizedData?.ip ? memoizedData?.ip : "loading"} location={memoizedData?.city ? `${memoizedData?.city}  ${memoizedData?.region}` :"loading"} ISP={ memoizedData?.isp ? ` ${memoizedData?.isp}` :"loading"} protected={status} />
      <HeaderDesign id="home">
      <LockerMenu /> 
      <Container maxWidth="lg"  sx={{ minHeight:{xs:"calc(100vh - 150px)",lg:"calc(100vh - 120px)"} ,display:"flex", flexDirection:"column", gap:"30px",justifyContent:"start",marginTop:"150px",marginBottom:"50px",paddingLeft:{xs:"20px"},paddingRight:{xs:"20px"}}}> 
        <Paragraph textAlign="left" fontSize={{xs:"",lg:"55px"}} fontWeight="600" > 
          Blog
        </Paragraph>
      <Grid container display="flex" justifyContent="start" alignItems="start" gap="25px" >    
            { 
              memofeatured?.id &&   <Grid item container xs={12} sm={12} md={12} lg={12} display="flex"   position="relative" borderRadius="10px" bgcolor="#1B1821" minHeight="auto" >
              <Grid item  xs={12} sm={12} md={5.7} lg={5.89} > 
              <Img  src={memofeatured?.featured_media?.url} h="315px" style={{objectFit:"cover",borderRadius:"10px"}} w="100%" alt="pic"  />  
              </Grid>
              <Grid item  xs={12} sm={12} md={5.7} lg={5.85} sx={{ display:"flex" ,flexDirection:"column", justifyContent:"start", alignItems:"start",gap:"7px",padding:"20px"}}>   
              <CustomButton m="0px"  b="1px solid #707070">feature</CustomButton> 
              <Mediumtxt   textAlign="left" fontSize={{xs:"19px",lg:"26px"}} fontWeight="600">{memofeatured?.title}</Mediumtxt> 
              {/* <Excerpt class="tooltip"> 
                  {countstring}
              <span class="tooltiptext">
               <p class="text"> { memofeatured?.excerpt}</p>
             </span>  
             </Excerpt> */}
              <Paragraph my="0px" textAlign="left" fontSize="16px"> {memofeatured?.excerpt}</Paragraph>    
              <Paragraph my="0px" textAlign="left" fontSize="16px">{memofeatured?.date}</Paragraph> 
              <CustomButton bgc="#1B1821" p="7px 8px" fs="16px" b="1px solid #707070" m="0px" w="90px"> <Link to={`/blog_detail/${memofeatured?.id}`}  style={{textDecoration:"none",color:"white"}}>Read</Link></CustomButton> 
              </Grid>
             </Grid>
            }
           <Grid item container xs={12} lg={12} sx={{display:"flex",flexWrap:"wrap",justifyContent:"start", alignItems:"start",gap:"25px"}}>  
          {  
           blog_data?.map((obj,index) => 
            <BlogCard xs={12} sm={12} md={5.7} lg={5.87}  key={index} psrc={obj?.featured_media?.url ? obj?.featured_media?.url :post3} def1={obj?.title} def2 ={obj?.date} id={obj?.id}  excerpt={obj?.excerpt} /> 
           ) 
          }
          </Grid>
        
      </Grid>
      </Container>
    </HeaderDesign>
    </Box>
  );
}
 
export default PagePost;