import React, { useRef } from 'react';
import Summary from '../../Components/Sections/S7-Summary';
import styled from '@emotion/styled';
import { useState,useEffect} from 'react';
import starGalaxyBg from "../../../assets/Images/newDesign/starGalaxyBg.jpg"
import axios from 'axios';
import End from '../../Components/WhiteLabel_Page/S2-End';
import AOS from 'aos';
import 'aos/dist/aos.css';
import WhitelabelHome from '../../Components/WhiteLabel_Page/S1_WhiteLabel'; 


const Styleddiv = styled.div`
    width:100%;
    overflow: hidden;
    scroll-snap-align:start;
    min-height:100vh;
    padding:50px 0;
    
  background-image: url(${starGalaxyBg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  @media(max-width: 899px){
    background-position: top;   
  background-size: auto 110vh;
  }
`
const FixedImage= styled.img`
  width:100%;
  height:auto;
  position:fixed;
  top:10%;
  left:-50%;
  z-index:1;
`
const Maindiv  = styled.div`
   overflow: hidden; 
   /* position : relative;   */
   /* min-height: 100vh; */
`  
const  TopRightimg = styled.img` 
     position: fixed;
    width: 40%;
    top: 0%;
    right: 0%;
    /* transform: translate(52%, -38%);  */
    z-index: 1; 
    @media(max-width:599px){
       width: 80% !important; 
       /* transform: translate(51%, -38%); */
    }
` 
const  BottomLeftimg = styled.img`
    position: fixed;
    width: 40%;
    bottom: 0%;
    left: 0%;
    /* transform: translate(-55%, 45%);  */
    z-index: 1;   
    opacity: (0.7%);
    @media(max-width:599px){
       width: 80% !important; 
       /* transform: translate(-55%, 37%); */
    }
`

 
const Whitelabel = () => {
    // const [location, setLocation] = useState({});  
    const [pageState, setPageState] =  useState(true);
    const [location, setLocation] = useState(null);
    useEffect(()=>{
      AOS.init({
      })
    })
    useEffect(() => {
      const fetchLocation = async () => {
        try {
          const ipResponse = await axios.get('https://api.ipify.org?format=json') 
          // console.log(ipResponse.data.ip)
          const response = await axios.get(`https://ipapi.co/${ipResponse?.data.ip}/json/`)
          .then(function (response) {
              // console.log("response",response);
              const data = response.data;  
                // console.log(data)
                setLocation({
                    ip: data?.ip, 
                    city: data?.city,
                    region: data?.region,
                    country: data?.country_name,
                    asn: data?.asn,
            isp: data?.org
          });
          })
          .catch(function (error) {
              console.error(error);
          });
          
        } catch (error) {
          console.error('Error fetching location:', error);
        }
      };
  
      fetchLocation();
    },[]);
     
 
    const tawkMessengerRef = useRef();
    const [isClient, setIsClient] = useState(false)
    useEffect(()=>{
      setTimeout(()=>{

        setIsClient(true)
      },1000)
    },[])
    return ( 
      <>    
           <Maindiv>  
            <Styleddiv>    
           <div id="stars"></div>
          <div id="stars2"></div> 
                <WhitelabelHome />  
                <End/>
            </Styleddiv>
            <Summary/>
        </Maindiv>   
   
         
      </>
    );
}
export default Whitelabel;