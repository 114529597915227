import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Img } from '../../../../Styles/Maintext.styles';
import Mediumtxt from '../../../Mediumtxt';
import Paragraph from '../../../Paragraph';
import CustomButton from '../../../CustomBtn';
import { Link } from 'react-router-dom'; 
import styled from '@emotion/styled'; 

 

const Excerpt = styled.div` 
     display: inline-block;
     /* border-bottom: 1px dotted black;  */
     color:#fff; 
     position : relative; 
     font-size: 16px; 
    &>.tooltiptext{     
        visibility: hidden;
        width: calc(100% - 40px);
        background-color:#262033;
        color: #fff;
         text-align: left;
         border-radius: 6px;
         padding:10px 20px;
        position: absolute;  
        top: 30px;
        left:0; 
        font-family:PoppinsL;
         /* Position the tooltip */
        z-index: 510; 
        &>.text{  
            font-size: 16px; 
            text-align: left; 
            color : white; 
            font-family:PoppinsL; 

         }
    } 
    :hover .tooltiptext {
    visibility: visible;
}
`
const   BlogCard = (props) => {    

     const [countstring , setCountstring]  = useState("");
   
     const excerpt = props.excerpt; 
    //  console.log("excerpt",excerpt); 

    function countWordsToLimit(str, limit) { 

        if (typeof str !== 'string' || typeof limit !== 'number' || limit < 1) {
            throw new Error('Invalid input: Please provide a valid string and a positive number limit.');
        }
    
        let words = str.trim().split(/\s+/); // Split the string by whitespace and remove any extra spaces
        let wordCount = 0;
        let limitedWords = [];
    
        for (let word of words) {
            if (wordCount >= limit) {
                break;
            }
            limitedWords.push(word);
            wordCount++;
        }
    
        return {
            wordCount: wordCount,
            words: limitedWords.join(' ')
        };
    }
         
    useEffect(() => { 
     const strword  = countWordsToLimit( excerpt, 21); 
     setCountstring(strword?.words); 
     console.log("Countstring",countstring)
     }, []); 
     console.log("ID",props.id);
    
    function decodeHtmlEntities(text) {
        return text.replace(/&#(\d+);/g, function(match, dec) {
            return String.fromCharCode(dec);
        });
    }
    return (
        <Grid item xs={12} sm={5} md={3.5} display="flex"  sx={{paddingTop:{xs:"0px", md:props.ptop}}} flexDirection="column" position="relative" borderRadius="10px" bgcolor={props.bg? props.bg: "#1B1821"} minHeight={{xs:"auto",lg:"580px"}} {...props}>
            <Img h="294px"  src={props.psrc} alt="pic" style={{objectFit:"cover",borderRadius:"10px"}} paddingLeft="20px !important" />
            <Box display="flex"  flexDirection="column" gap="7px" padding={props.padd ? props.padd:"20px"}>
                <Mediumtxt   textAlign="left" fontSize={{xs:"19px",lg:"26px"}} fontWeight="600">{decodeHtmlEntities(props.def1)}</Mediumtxt>  
                <Excerpt class="tooltip"> 
                    {decodeHtmlEntities(countstring)}.....
                    <span class="tooltiptext">
                        <p class="text">{decodeHtmlEntities(props.excerpt)}</p>
                    </span>  
                </Excerpt>
                <Paragraph my="0px" textAlign="left" fontSize="16px">{decodeHtmlEntities(props.def2)}</Paragraph>   
                <CustomButton bgc="#1B1821" p="7px 8px" fs="16px" b="1px solid #707070" m="8px 0px" w="90px"> <Link to={`/blog_detail/${props.id}`}  style={{textDecoration:"none",color:"white"}}>Read</Link></CustomButton> 
            </Box>
        </Grid>
      );
}
 
export default BlogCard; 